#login{
    background-color:darkgrey;
    background-image: url('https://mayaland-src.s3.us-east-2.amazonaws.com/login2.jpg');
    background-size:cover;
    
    
    
}

.blueBorder{
    border-color: rgb(3, 14, 108) !important;
}

.fixContainer{
    min-height: 94.5vh;
}


.contLogin{
    background:rgb(255, 255, 255); 
    min-height: 50vh;
    margin-top: 4%;
    margin-bottom: 5%;
    border: 0.5px solid rgb(182, 182, 182);
}

.userImg{
    width: 200px;
    height: auto;
}

.userImg img{
    width: 100%;
}
