#login{
    background-color:darkgrey;
    background-image: url('https://mayaland-src.s3.us-east-2.amazonaws.com/login2.jpg');
    background-size:cover;
    
    
    
}

.blueBorder{
    border-color: rgb(3, 14, 108) !important;
}

.fixContainer{
    min-height: 94.5vh;
}


.contLogin{
    background:rgb(255, 255, 255); 
    min-height: 50vh;
    margin-top: 4%;
    margin-bottom: 5%;
    border: 0.5px solid rgb(182, 182, 182);
}

.userImg{
    width: 200px;
    height: auto;
}

.userImg img{
    width: 100%;
}

@media screen and (max-width:614px) {
    .dissapearObject {
        display: none;
    }
}

@media screen and (min-width:614px) {
    .appearMovileObject {
        display: none;
    }

}

.especialButtonClose {
    background: none;
    border-color: none;
    border: none;
    font-size: 35px;
}


.especialButtonClose {
    margin-bottom: 10px;
}

.generalContainer {
    margin-top: 40px;
    margin-bottom: 1px;
}

.backLeyendSoftGreen {
    background: #6bb974c8;

}
.color-box{
    width: 20px;
    height: 20px;

    display: inline-block;
    margin-right: 10px;
  }

.backLeyendSoftYellow {
    background: #e6e183c8;

}

.backLeyendSoftRed {
    background: #cb7474c8;

}



.posIndex {
    z-index: 2;
}

.notcounted {
    background-color: rgba(45, 48, 48, 0.578);
}

.correctCount {
    background-color: rgba(12, 82, 2, 0.578);
}

.listDecoratorNone {
    list-style-type: none;
    display: flex;
    padding: 0;
}



.negativeCount {
    background-color: rgba(119, 30, 30, 0.578);
}

.positiveCount {
    background-color: rgba(2, 87, 93, 0.578);
}


.greenBackground {
    background-color: rgba(71, 180, 56, 0.578);
}

.redBackground {
    background-color: rgba(192, 0, 0, 0.347);
}

.yellowBackground {
    background-color: rgba(230, 197, 76, 0.462);
}



.notcounted2 {
    background-color: rgba(45, 48, 48, 0.493);
    color: rgb(255, 255, 255);
}

.correctCount2 {
    background-color: rgba(11, 82, 2, 0.581);
    color: rgb(255, 255, 255);
}

.negativeCount2 {
    background-color: rgba(119, 30, 30, 0.626);
    color: rgb(255, 255, 255);
}

.positiveCount2 {
    background-color: rgba(2, 87, 93, 0.692);
    color: rgb(255, 255, 255);
}

.lobbyContainer {
    min-height: 52vh;
}

.textAreaGeneral textarea {
    resize: none;
}

.textAreaComment {
    width: 100%;
}


.detailIMG {
    width: 170px;
}

.justifyText {
    text-align: justify;
}


.greenButton {
    background-color: rgb(1, 91, 22);
    color: aliceblue;
}

.greenButton:hover {
    background-color: rgb(0, 124, 29);
    color: aliceblue;
}

.blueButton {
    background-color: rgb(22, 9, 75);
    color: aliceblue;
}

.iconColor {
    color: aliceblue;
    background-color: aliceblue;
}

.blueButton:hover {
    background-color: rgb(37, 15, 129);
    color: aliceblue;
}

.pText p {
    font-size: 20px;
}

.brownButton {
    background-color: rgb(101, 16, 16);
    color: aliceblue;
}

.brownButton:hover {
    background-color: rgb(131, 25, 25);
    color: aliceblue;
}

.orangeButton {
    background-color: rgb(164, 56, 5);
    color: aliceblue;
}

.orangeButton:hover {
    background-color: rgb(198, 67, 6);
    color: aliceblue;
}

.yellowButton {
    background-color: rgb(217, 175, 23);
    color: rgb(0, 0, 0);
}

.yellowButton:hover {
    background-color: rgb(255, 206, 28);
    color: rgb(0, 0, 0);
}

.redButton {
    background-color: rgb(177, 0, 0);
    color: aliceblue;
}

.redButton:hover {
    background-color: rgb(243, 0, 0);
    color: aliceblue;
}

.blackButton {
    background-color: rgb(39, 38, 38);
    color: aliceblue;
}

.blackButton:hover {
    background-color: rgb(0, 0, 0);
    color: aliceblue;
}

.aquaButton {
    background-color: rgb(11, 105, 97);
    color: aliceblue;
}

.aquaButton:hover {
    background-color: rgb(18, 162, 150);
    color: aliceblue;
}


.contPrincipal {
    min-height: 100vh;
}

.yellowBackground {
    background-color: rgb(241, 195, 29);
    color: black;
}

.orangeBackground {
    background-color: rgb(164, 56, 5);
    color: whitesmoke;
}

.InputText p {
    font-size: 20px;
    word-wrap: break-word;
}

.InputTextBolder p {
    font-size: 20px;
    font-weight: bolder;
}

.InputTextBolderSimple {
    font-weight: bold;
}

.TitleText p {
    font-size: 30px;
    word-wrap: break-word;
}

.TitleText {
    font-size: 20px;
}


.grayBackground {
    background-color: rgb(198, 197, 197);
    color: rgb(0, 0, 0);
}

.textTableSize {
    word-wrap: break-word;
    font-size: 21px;
}

.checkBoxTitle {

    font-size: 31px;
    font-weight: bolder;
}

.textRadioButtonSize {
    font-size: 150%;
}

@media screen and (max-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

@media screen and (max-width:1060px)and (min-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

.ItemImg {
    width: 350px;
    height: 350px;


}

.ItemImg img {
    /*object-fit: contain;*/
    width: auto;
    height: 100%;

}

.AlignImgCenter {
    margin: auto;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxSize {
    scale: 1.5;
}


.backgroundGreen {
    background-color: rgb(17, 112, 21) !important;
}

.backgroundGray {
    background-color: rgb(197, 197, 197) !important;
}

.backgroundGrayLow {
    background-color: rgb(238, 238, 238) !important;
}

.backgroundOrange {
    background-color: rgb(243, 130, 10) !important;
}

.backgroundBlue {
    background-color: rgb(3, 14, 108) !important;
}

.backgroundWhite {
    background-color: rgb(255, 255, 255) !important;
}

.purpleButton {
    background-color: rgb(61, 11, 94);
    color: aliceblue;
}

.purpleButton:hover {
    background-color: rgb(110, 21, 170);
    color: aliceblue;
}


.backgroundSky {
    background-color: rgb(255, 255, 255) !important;
}

.backgroundSky2 {
    background-color: rgb(191, 192, 193) !important;
}

.backgroundSky:hover {
    background-color: rgb(191, 192, 193) !important;
}



.InputTextBolder2 p {
    font-size: 18px;
    font-weight: bolder;
}



.nonTextDecoration {
    text-decoration: none;
}

.inputResize {

    width: 75px !important;
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .inputResize {
        width: 75px !important;

    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .inputResize {
        width: 75px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .inputResize {

        width: 75px !important;
    }
}

.colorHead {
    background-color: rgb(30, 38, 146);
    min-height: 15px;
}

.dashboardText {
    font-size: 28px;
}


.softGreen {
    background-color: rgba(3, 241, 90, 0.122) !important;
}

.softYellow {
    background-color: rgba(241, 221, 3, 0.122) !important;
}

.softRed {
    background-color: rgba(241, 3, 3, 0.122) !important;
}

.softGray {
    background-color: rgba(129, 129, 129, 0.122) !important;
}
.logoFooter img{
    width: 55px;
    margin-bottom: 1%;
}

.GenFooter{
    width: 100%;
    min-height: 175px;
    clear: both;


    
}

@media screen and (max-width:453px) {
    .logoFooter img{
        width: 50px;
        overflow: hidden;
    }
}
.menuCompany .lateralMenu{
    background:rgb(1, 35, 57);
    font-size: 23px;
}

.menuCompany .lateralMenu h4{
    margin-left: 5%;
}
.menuCompany .lateralMenu ul{
    margin-left: 5%;
}

.menuCompany .dropDownMenu{
    background:rgb(2, 46, 74);
}
.menuCompany .dropDownMenu li a:hover{
    background:rgb(4, 69, 110);
}


.menuCompany .navbar-brand img{
    width: 89px;
    margin-left: 5%;
}

@media screen and (max-width:613px) {
    .menuCompany .navbar-brand img{
        width: 55px;
        margin-left: 5%;
    }
}

@media screen and (max-width:1060px)and (min-width:589px) {
    .menuCompany .navbar-brand img{
        width: 57px;
        margin-left: 5%;
    }
}

.menuCompany .filling{
    height: 50px;
    
}
.title{
    color: rgb(7, 39, 71);
}



.decoratorBar{
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
    width: 90%;
    padding: 6px;
    background-color: rgb(168, 15, 15);
}



 .titecompany p{
    margin-top: 15%;
    color:rgb(255, 250, 245);
    font-weight: bolder;
    font-size: 3.5vh;
    
 }

 .CompImg{
    margin-top: 7.5%;
    width: 310px;
    height: 70px;
   
}
.CompImg img{
    width: 100%;
    height: auto;
}

.backgroundBlack{
    background-color: rgb(40, 38, 38);
    min-height: 40vh;
}

.borderStyleComp{
    border-radius: 30%  / 10% ;
}



.my-custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}


.tableFixHead          { overflow: auto; min-height: 10vh; max-height: 70vh; margin-bottom: 5%; z-index: 0; }
.tableFixHead thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; }


.hightSizeTable { min-width:600vh;}

table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
.fs-mini{
  font-size: 12px;
}
.row_hover:hover {
  background-color: rgb(151, 255, 119);
  transition: 0.1s;
}
.row_hover {
  transition: 0.1s;
}




.sticky-col
{
  
  position:-webkit-sticky;
  
  position:sticky;
  left:0px;
  background-color:rgb(255, 255, 255);
  z-index: 4!important;
}
.sticky-col-head
{
  position:-webkit-sticky;
  position:sticky;
  left:0px;
  background-color:rgb(0, 0, 0)!important;
  z-index: 5!important;
}

.tableAnidada{
  margin-top: 35px;
}


.positionStartText{
  text-align: left;
}
.positionEndText{
  text-align: right;
}

.colfix{
  width: 1000px!important;
}

 
.searchPart .col-5{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
   
}

.searchProd{
    background-color: rgb(1, 91, 22);
    color: aliceblue;
}

.searchProd:hover{
    background-color: rgb(0, 124, 29);
    color: aliceblue;
}

.catalogueOpen{
    background-color: rgb(22, 9, 75);
    color: aliceblue;
}

.catalogueOpen:hover{
    background-color: rgb(37, 15, 129);
    color: aliceblue;
}


.historyProd{
    background-color: rgb(101, 16, 16);
    color: aliceblue;
}

.historyProd:hover{
    background-color: rgb(131, 25, 25);
    color: aliceblue;
}
.titleTotal p{
    font-weight: bold;
    font-size: 25px;
}

.titleDistribution p{
    
    font-size: 20px;
}


.searchPart p{
    
    font-size: 25px;
}

.ItemCodeNumber{
    background-color: rgb(15, 15, 66);
    color: aliceblue;
}


.ItemCodeNumber p{
    margin-top: 19%;
    font-weight: bold;
}

.InventoryIMG{
    width: auto;
    height: 350px;
}
.InventoryIMG img{
    width: auto;
    height: 100%;
}




.productInfoText p{
    font-size: 20px;
}

.desciptioninfo p{
    font-size: 15px;
    margin-top: 3%;
}



@media screen and (max-width:613px) {
    .ItemCodeNumber img{
        width:100px;
    }
}

@media screen and (max-width:1060px)and (min-width:589px) {
    .ItemCodeNumber img{
        width: 120px;
    }
}
.ConfirmUpload img{
    background-color: white;
    width: 90%;
}

.imgC{
    text-align: center;
    height: 170px;
    max-width: 100%
}
.cartaHover:hover{
    background-color:rgb(232, 232, 232);
    transition: 0.2s;
}

.cartaHover{
    font-family:Helvetica;
    transition: 0.2s;
}
.ItemImage{
    width: auto;
    height: 350px;
  
    
}
@media only screen and (max-width:576px) {
    .ItemImage  {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
@media  screen and (min-width:577px) {
    .ItemImage  {
        margin-top: -89px;
    }
}

.ItemImage img{
    width: auto;
    height: 100%;
}




.scrollBar {
	height: 50vh;
	border: 1px solid #ddd;
	overflow-y: scroll;
}

.FixedTitle{
    overflow: auto; 
    min-height: 87vh; 
    max-height: 87vh; 
    z-index: 0;
}
.FixedTitle2{
    overflow: auto; 
    min-height: 87vh; 
    max-height: 87vh;
    z-index: 0;
}

.PrincipalHead{
    position: -webkit-sticky;
    position: sticky;
    min-height: 15vh;
    max-height: 15vh;
    top: 0; 
    z-index: 1;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.PrincipalHead2{
    position: -webkit-sticky;
    position: sticky;
    min-height: 12vh;
    max-height: 12vh;
    top: 0; 
    z-index: 1;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.controlZIndex{
    z-index: 0;
}

.performance{
    width: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1246px) {
    .performance{
        width: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .performance{
        width: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .performance{
        width: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .performance{
        width: none;
    }
}

.my-custom-scrollbar {
    position: relative;
    height: 500px;
    overflow: auto;
  }
  .table-wrapper-scroll-y {
    display: block;
  }
  
  
  .tableFixHeadSmall          { overflow: auto; min-height: 10vh; max-height: 45vh; margin-bottom: 5%; z-index: 0; }
  .tableFixHeadSmall thead th { position: -webkit-sticky; position: sticky; top: 0; z-index: 1; }
  
  
  table  { border-collapse: collapse; width: 100%; }
  th, td { padding: 8px 16px; }
  th     { background:#eee; }
  .fs-mini{
    font-size: 12px;
  }
  .row_hover:hover {
    background-color: rgb(151, 255, 119);
    transition: 0.1s;
  }
  .row_hover {
    transition: 0.1s;
  }

.react-calendar {
    
    max-width: 100%;
    background: rgb(227, 227, 227);
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    background-color: #13025e;
    color: #f0f0f0!important;
  }
  .react-calendar__navigation span{

    color: #f0f0f0!important;
  }
  .react-calendar__navigation button{

    color: #f0f0f0!important;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  

  .salesCalendar{
    color: #025e0d;
    font-weight: bold;
    font-size: small;
    text-align: center;
  }
  .quantSalesCalendar{
    color: #02085e;
    font-weight: bold;
    font-size: small;
    text-align: center;
  }
  .color-box{
    width: 20px;
    height: 20px;

    display: inline-block;
    margin-right: 10px;
  }
  .text-blockLeyend{
    display: inline-block;
    text-align: left;
  }

  .backLeyendBlue{
    background: #13025e;
  }
  .backLeyendGreen{
    background:#025e0d;
    
  }

  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #040891;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    background-color: #848484;
    color: #e6e6e6;
  }

  .weeknoview {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    background-color: #848484;
    color: #e6e6e6;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    border: 1px solid #000000;

  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    border: 1px solid #000000;
    max-height:  101.4px;
  }
  .react-calendar__month-view__weekNumbers{
  
    order: 2;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
    
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #000000;
    
    
  }

   .react-calendar__month-view__days__day {
    border: 1px solid black !important;
    
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: left;
    line-height: 16px;
    font-size: smaller;
    font-weight: bold;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  
  .react-calendar__tile--now {
    background: #ffff76;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
.lateralMenu{
    background:rgb(1, 35, 57);
    font-size: 23px;
}

.lateralMenu h4{
    margin-left: 5%;
}
.lateralMenu ul{
    margin-left: 5%;
}

.dropDownMenu{
    background:rgb(2, 46, 74);
}
.dropDownMenu li a:hover{
    background:rgb(4, 69, 110);
}


.navbar-brand img{
    width: 180px;
    margin-left: 5%;
}



@media only screen and (min-width: 300px) {
    .navbar-brand img{
        width: 90.1px;
        margin-left: 5%;
    }
}

@media only screen and (min-width: 375px) {
    .navbar-brand img{
        width: 100.1px;
        margin-left: 5%;
    }
}
@media only screen and (min-width: 414px) {
    .navbar-brand img{
        width: 120.1px;
        margin-left: 5%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

.filling{
    height: 35px;
    
}
