.imgC{
    text-align: center;
    height: 170px;
    max-width: 100%
}
.cartaHover:hover{
    background-color:rgb(232, 232, 232);
    transition: 0.2s;
}

.cartaHover{
    font-family:Helvetica;
    transition: 0.2s;
}