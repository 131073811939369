.ItemImage{
    width: auto;
    height: 350px;
  
    
}
@media only screen and (max-width:576px) {
    .ItemImage  {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
@media  screen and (min-width:577px) {
    .ItemImage  {
        margin-top: -89px;
    }
}

.ItemImage img{
    width: auto;
    height: 100%;
}



