.title{
    color: rgb(7, 39, 71);
}



.decoratorBar{
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4%;
    width: 90%;
    padding: 6px;
    background-color: rgb(168, 15, 15);
}



 .titecompany p{
    margin-top: 15%;
    color:rgb(255, 250, 245);
    font-weight: bolder;
    font-size: 3.5vh;
    
 }

 .CompImg{
    margin-top: 7.5%;
    width: 310px;
    height: 70px;
   
}
.CompImg img{
    width: 100%;
    height: auto;
}

.backgroundBlack{
    background-color: rgb(40, 38, 38);
    min-height: 40vh;
}

.borderStyleComp{
    border-radius: 30%  / 10% ;
}

