@media screen and (max-width:614px) {
    .dissapearObject {
        display: none;
    }
}

@media screen and (min-width:614px) {
    .appearMovileObject {
        display: none;
    }

}

.especialButtonClose {
    background: none;
    border-color: none;
    border: none;
    font-size: 35px;
}


.especialButtonClose {
    margin-bottom: 10px;
}

.generalContainer {
    margin-top: 40px;
    margin-bottom: 1px;
}

.backLeyendSoftGreen {
    background: #6bb974c8;

}
.color-box{
    width: 20px;
    height: 20px;

    display: inline-block;
    margin-right: 10px;
  }

.backLeyendSoftYellow {
    background: #e6e183c8;

}

.backLeyendSoftRed {
    background: #cb7474c8;

}



.posIndex {
    z-index: 2;
}

.notcounted {
    background-color: rgba(45, 48, 48, 0.578);
}

.correctCount {
    background-color: rgba(12, 82, 2, 0.578);
}

.listDecoratorNone {
    list-style-type: none;
    display: flex;
    padding: 0;
}



.negativeCount {
    background-color: rgba(119, 30, 30, 0.578);
}

.positiveCount {
    background-color: rgba(2, 87, 93, 0.578);
}


.greenBackground {
    background-color: rgba(71, 180, 56, 0.578);
}

.redBackground {
    background-color: rgba(192, 0, 0, 0.347);
}

.yellowBackground {
    background-color: rgba(230, 197, 76, 0.462);
}



.notcounted2 {
    background-color: rgba(45, 48, 48, 0.493);
    color: rgb(255, 255, 255);
}

.correctCount2 {
    background-color: rgba(11, 82, 2, 0.581);
    color: rgb(255, 255, 255);
}

.negativeCount2 {
    background-color: rgba(119, 30, 30, 0.626);
    color: rgb(255, 255, 255);
}

.positiveCount2 {
    background-color: rgba(2, 87, 93, 0.692);
    color: rgb(255, 255, 255);
}

.lobbyContainer {
    min-height: 52vh;
}

.textAreaGeneral textarea {
    resize: none;
}

.textAreaComment {
    width: 100%;
}


.detailIMG {
    width: 170px;
}

.justifyText {
    text-align: justify;
}


.greenButton {
    background-color: rgb(1, 91, 22);
    color: aliceblue;
}

.greenButton:hover {
    background-color: rgb(0, 124, 29);
    color: aliceblue;
}

.blueButton {
    background-color: rgb(22, 9, 75);
    color: aliceblue;
}

.iconColor {
    color: aliceblue;
    background-color: aliceblue;
}

.blueButton:hover {
    background-color: rgb(37, 15, 129);
    color: aliceblue;
}

.pText p {
    font-size: 20px;
}

.brownButton {
    background-color: rgb(101, 16, 16);
    color: aliceblue;
}

.brownButton:hover {
    background-color: rgb(131, 25, 25);
    color: aliceblue;
}

.orangeButton {
    background-color: rgb(164, 56, 5);
    color: aliceblue;
}

.orangeButton:hover {
    background-color: rgb(198, 67, 6);
    color: aliceblue;
}

.yellowButton {
    background-color: rgb(217, 175, 23);
    color: rgb(0, 0, 0);
}

.yellowButton:hover {
    background-color: rgb(255, 206, 28);
    color: rgb(0, 0, 0);
}

.redButton {
    background-color: rgb(177, 0, 0);
    color: aliceblue;
}

.redButton:hover {
    background-color: rgb(243, 0, 0);
    color: aliceblue;
}

.blackButton {
    background-color: rgb(39, 38, 38);
    color: aliceblue;
}

.blackButton:hover {
    background-color: rgb(0, 0, 0);
    color: aliceblue;
}

.aquaButton {
    background-color: rgb(11, 105, 97);
    color: aliceblue;
}

.aquaButton:hover {
    background-color: rgb(18, 162, 150);
    color: aliceblue;
}


.contPrincipal {
    min-height: 100vh;
}

.yellowBackground {
    background-color: rgb(241, 195, 29);
    color: black;
}

.orangeBackground {
    background-color: rgb(164, 56, 5);
    color: whitesmoke;
}

.InputText p {
    font-size: 20px;
    word-wrap: break-word;
}

.InputTextBolder p {
    font-size: 20px;
    font-weight: bolder;
}

.InputTextBolderSimple {
    font-weight: bold;
}

.TitleText p {
    font-size: 30px;
    word-wrap: break-word;
}

.TitleText {
    font-size: 20px;
}


.grayBackground {
    background-color: rgb(198, 197, 197);
    color: rgb(0, 0, 0);
}

.textTableSize {
    word-wrap: break-word;
    font-size: 21px;
}

.checkBoxTitle {

    font-size: 31px;
    font-weight: bolder;
}

.textRadioButtonSize {
    font-size: 150%;
}

@media screen and (max-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

@media screen and (max-width:1060px)and (min-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

.ItemImg {
    width: 350px;
    height: 350px;


}

.ItemImg img {
    /*object-fit: contain;*/
    width: auto;
    height: 100%;

}

.AlignImgCenter {
    margin: auto;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxSize {
    scale: 1.5;
}


.backgroundGreen {
    background-color: rgb(17, 112, 21) !important;
}

.backgroundGray {
    background-color: rgb(197, 197, 197) !important;
}

.backgroundGrayLow {
    background-color: rgb(238, 238, 238) !important;
}

.backgroundOrange {
    background-color: rgb(243, 130, 10) !important;
}

.backgroundBlue {
    background-color: rgb(3, 14, 108) !important;
}

.backgroundWhite {
    background-color: rgb(255, 255, 255) !important;
}

.purpleButton {
    background-color: rgb(61, 11, 94);
    color: aliceblue;
}

.purpleButton:hover {
    background-color: rgb(110, 21, 170);
    color: aliceblue;
}


.backgroundSky {
    background-color: rgb(255, 255, 255) !important;
}

.backgroundSky2 {
    background-color: rgb(191, 192, 193) !important;
}

.backgroundSky:hover {
    background-color: rgb(191, 192, 193) !important;
}



.InputTextBolder2 p {
    font-size: 18px;
    font-weight: bolder;
}



.nonTextDecoration {
    text-decoration: none;
}

.inputResize {

    width: 75px !important;
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .inputResize {
        width: 75px !important;

    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .inputResize {
        width: 75px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .inputResize {

        width: 75px !important;
    }
}

.colorHead {
    background-color: rgb(30, 38, 146);
    min-height: 15px;
}

.dashboardText {
    font-size: 28px;
}


.softGreen {
    background-color: rgba(3, 241, 90, 0.122) !important;
}

.softYellow {
    background-color: rgba(241, 221, 3, 0.122) !important;
}

.softRed {
    background-color: rgba(241, 3, 3, 0.122) !important;
}

.softGray {
    background-color: rgba(129, 129, 129, 0.122) !important;
}