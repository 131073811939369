.lateralMenu{
    background:rgb(1, 35, 57);
    font-size: 23px;
}

.lateralMenu h4{
    margin-left: 5%;
}
.lateralMenu ul{
    margin-left: 5%;
}

.dropDownMenu{
    background:rgb(2, 46, 74);
}
.dropDownMenu li a:hover{
    background:rgb(4, 69, 110);
}


.navbar-brand img{
    width: 180px;
    margin-left: 5%;
}



@media only screen and (min-width: 300px) {
    .navbar-brand img{
        width: 90.1px;
        margin-left: 5%;
    }
}

@media only screen and (min-width: 375px) {
    .navbar-brand img{
        width: 100.1px;
        margin-left: 5%;
    }
}
@media only screen and (min-width: 414px) {
    .navbar-brand img{
        width: 120.1px;
        margin-left: 5%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

.filling{
    height: 35px;
    
}