.searchPart .col-5{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
   
}

.searchProd{
    background-color: rgb(1, 91, 22);
    color: aliceblue;
}

.searchProd:hover{
    background-color: rgb(0, 124, 29);
    color: aliceblue;
}

.catalogueOpen{
    background-color: rgb(22, 9, 75);
    color: aliceblue;
}

.catalogueOpen:hover{
    background-color: rgb(37, 15, 129);
    color: aliceblue;
}


.historyProd{
    background-color: rgb(101, 16, 16);
    color: aliceblue;
}

.historyProd:hover{
    background-color: rgb(131, 25, 25);
    color: aliceblue;
}
.titleTotal p{
    font-weight: bold;
    font-size: 25px;
}

.titleDistribution p{
    
    font-size: 20px;
}


.searchPart p{
    
    font-size: 25px;
}

.ItemCodeNumber{
    background-color: rgb(15, 15, 66);
    color: aliceblue;
}


.ItemCodeNumber p{
    margin-top: 19%;
    font-weight: bold;
}

.InventoryIMG{
    width: auto;
    height: 350px;
}
.InventoryIMG img{
    width: auto;
    height: 100%;
}




.productInfoText p{
    font-size: 20px;
}

.desciptioninfo p{
    font-size: 15px;
    margin-top: 3%;
}



@media screen and (max-width:613px) {
    .ItemCodeNumber img{
        width:100px;
    }
}

@media screen and (max-width:1060px)and (min-width:589px) {
    .ItemCodeNumber img{
        width: 120px;
    }
}
.ConfirmUpload img{
    background-color: white;
    width: 90%;
}
